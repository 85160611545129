import React from "react"
import HeaderSub from "../components/header_sub"
import LayoutInner from "../components/layout_inner"
import LayoutOuter from "../components/layout_outer"

export default function Contact() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="py-16">
          <div className="pb-16">
            <HeaderSub
              title="Contact"
              subtitle="Request help or simply ask a question"
            />
          </div>

          <p className="text-medium text-center text-gray-800 dark:text-gray-200">
            <a
              data-mce-href="mailto:tempory.app@gmail.com"
              href="mailto:tempory.app@gmail.com"
              title="E-Mail"
            >
              {" "}
              Mail: tempory.app@gmail.com
            </a>
          </p>
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
